.ant-btn-primary:not([disabled]) {
  &:hover {
    background-color: #264C9D;
    border-color: #264C9D;
    color: #fff;
    &:active {
      background-color: #fff;
      border-color: #264C9D;
      color: #264C9D;
    }
  }
  &:active {
    background-color: #fff;
    border-color: #264C9D;
    color: #264C9D;
  }
  &:focus {
    background-color: #264C9D;
    color: #fff;
  }
}
