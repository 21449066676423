body, #root {
    overflow-x: hidden;
}

.particles {
    position: absolute;
    max-width: 100vw;
    right: 0;
    left: 0;
    top: -200px;
    bottom: 0;
    z-index: -1;
}

.layout-mobile {
    overflow-x: hidden;

    .particles {
        position: fixed;
        max-width: none;
        top: 0;
        transform: rotate(-90deg);
        // left: calc(-100vh / 2 + 100px);
        // width: 100vh;
    }

    .layout-requirements,
    .layout-about {
        background-color: #fff;
    }
}

.slick-slider {
    .layout-about {
        position: relative;
    }

    .particles {
        transition: left ease 500ms;
    }

    .particles.noanimation {
        transition: none;
    }
}

.container {
    position: relative;
    z-index: 1;
}

.layout-advantages, .layout-directions {
    & > div:before {
        content: '';
        position: absolute;
        transform: skew(-20deg);
        background-color: #264C9D;
        top: 0;
        left: -80%;
        right: 0;
        bottom: 0;
        width: 100%;
        min-height: 100vh;
        z-index: -2;

        @media (max-width: 960px) {
            transform: skew(0deg);
            left: 0;
            z-index: 1;
        }
    }
}

.layout-conditions, .layout-requirements {
    & > div:before {
        content: '';
        position: absolute;
        transform: skew(-20deg);
        background-color: #264C9D;
        top: 0;
        left: 20%;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -2;

        @media (max-width: 960px) {
            display: none;
            z-index: 1;
        }
    }
}
