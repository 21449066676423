@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Bold.woff2') format('woff2'),
  url('../assets/fonts/Helvetica-Bold.woff') format('woff'),
  url('../assets/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Regular.woff2') format('woff2'),
  url('../assets/fonts/Helvetica-Regular.woff') format('woff'),
  url('../assets/fonts/Helvetica-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
}

canvas {
  display: block;
}

* {
  box-sizing: border-box;
}

a {
  color: #1890ff;
}

@import 'inputs.scss';
@import 'menu.scss';
@import 'modals.scss';
@import 'background.scss';
@import 'btn.scss';

.slick-dots {
  li {
    background-color: black;
  }
}
