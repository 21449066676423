.ant-modal-content {
  .ant-btn-lg {
    width: 100%;
    font-size: 30px;
  }
  .link-span {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #4468ab;
    }
  }
}

.modal-blue {
  z-index: 8888;

  .ant-modal-content {
    box-shadow: none;
  }
  @media (min-width: 961px) {
    background: #264C9D;
    padding-bottom: 0;
    .ant-modal-header {
      background: #264C9D;
      border: 0;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-body {
      color: #fff;
      background: #264C9D;
      h3 {
        color: #fff;
      }
      ul {
        padding-left: 40px;
      }
    }
    .controls {
      display: flex;
      justify-content: flex-end;
    }
    .ant-btn {
      height: 40px;
      padding: 0 25px;
      font-size: 16px;
      float: right;
      &:hover {
        color: #264C9D;
        background: #fff;
      }
    }
  }
  .ant-modal-header {
    border: 0;
    .ant-modal-title {
      font-size: 22px;
      color: #fff;
    }
  }
  .ant-modal-body {
    ul {
      padding-left: 20px;
    }
  }
  @media (max-width: 960px) {
    .controls {
      text-align: center;
      margin-top: 16px;
    }
    .ant-modal-close-x {
      & > span {
        width: 24px;
        height: 24px;
        background-size: 24px;
        background-repeat: no-repeat;
        display: inline-block;
        font-weight: bold;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Mi42OSA0Mi42OSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRjOWQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT7QoNC10YHRg9GA0YEgMTQ8L3RpdGxlPjxnIGlkPSLQodC70L7QuV8yIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDIiPjxnIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iLTQuNDEiIHk9IjE2LjkxIiB3aWR0aD0iNTEuNSIgaGVpZ2h0PSI4Ljg3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOC44NCAyMS4zNCkgcm90YXRlKC00NSkiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9Ii00LjQxIiB5PSIxNi45MSIgd2lkdGg9IjUxLjUiIGhlaWdodD0iOC44NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjEuMzQgLTguODQpIHJvdGF0ZSg0NSkiLz48L2c+PC9nPjwvc3ZnPg==');
      }
    }
    .ant-modal-header {
      .ant-modal-title {
        color: #264C9D;
      }
    }
    .ant-modal-body {
      background: #fff;
      color: #264C9D;
      h3 {
        color: #264C9D;
      }
    }
  }
}

.modal-reg {
  z-index: 9999;

  .ant-form-item {
    margin-bottom: 25px;
  }
  .text-center {
    text-align: center;
  }
  .ant-modal-header {
    border: 0;
    padding: 60px 24px 16px;
  }
  .ant-modal-content {
    border: 1px solid #264C9D;
    .ant-modal-body {
      background-color: #fff;
    }
  }
  .ant-modal-title {
    color: #264C9D;
    font-weight: bold;
    font-size: 24px;
  }
  .ant-modal-close {
    top: 15px;
    right: 15px;
  }
}

.m-t-20 {
  margin-top: 20px;
}

.modal-application {
  z-index: 9999;

  .ant-form-item {
    margin-bottom: 25px;
  }
  .ant-modal-header {
    border: 0;
    padding: 60px 24px 16px;
  }
  .ant-modal-title {
    color: #264C9D;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    margin-top: -38px;
    padding-right: 50px;
  }
  .ant-modal-close {
    top: 15px;
    right: 15px;
  }
  .ant-form-item-control {
    line-height: 100%;
  }
  .ant-modal-content {
    border: 1px solid #264C9D;
  }
  .ant-modal-body {
    padding: 30px 160px 30px 80px;
    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 24px;
      color: #264C9D;
      margin: 20px 0 10px;
    }
    .ant-btn {
      width: 100%;
      font-size: 15px;
      min-height: 50px;
    }
    .form-submit {
      margin-top: 20px;
    }
    & > .ant-row {
      display: flex;
      & > .ant-col-12 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &:first-child {
          padding-right: 35px;
        }
        &:last-child {
          padding-left: 35px;
        }
        .form-group {
          .ant-form-item {
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .education {
    justify-content: flex-end;
  }
}

.success-wrap {
  padding: 50px 35px 0;
  text-align: center;
  margin-right: -40px;
}

.nestle-opd {
  padding: 0 15px 0;
  p {
    text-align: justify;
  }
}

@media (max-width: 960px) {
  .nestle-opd {
    padding: 40px 0 0;
    font-size: 0.8rem;
    h3 {
      margin-bottom: 0;
    }
    .text-center {
      margin-bottom: 10px;
    }
  }
  .success-wrap {
    padding: 40px 0 0;
    margin-bottom: -10px;
    line-height: normal;
    text-align: left;
    font-size: 16px;
  }
  .ant-modal {
    top: 20px;
  }
  .ant-modal.modal-application {
    .ant-modal-body {
      padding: 20px;
      h3 {
        font-size: 16px;
        line-height: 20px;
      }
      .ant-row {
        flex-direction: column;
        & > .ant-col-12 {
          padding: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

