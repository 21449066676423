button {
  outline: 0;
}

.ant-btn-lg {
  padding-left: 50px;
  padding-right: 50px;
  line-height: 100%;
  @media (max-width: 1440px) {
    font-size: 32px;
  }
}

.ant-btn:not(.ant-btn-primary) {
  &:hover, &:focus {
    color: #fff;
    border-color: #264C9D;
  }
}

.ant-form-explain {
  margin-top: 2px;
  border-color: #264C9D;
}

.ant-select {
  width: 100%;
}

.ant-input-lg {
  font-size: 30px;
}

.ant-checkbox-group {
  display: flex;
  .ant-checkbox-wrapper {
    margin-right: 20px;
  }
}

.ant-checkbox-wrapper,.opd-checkbox {
  display: flex !important;
  align-items: baseline;
  span {
    color: rgba(38, 76, 157, 0.5);
  }
}
.opd-checkbox {
  margin-top: 15px;

  .ant-checkbox-wrapper {
    top: 3px;

    & + span {
      padding-left: 3px;
    }

    .ant-checkbox + span {
      line-height: 19px;
    }
  }
}

.ant-checkbox {
  top: 4px;
  & + span {
    color: rgba(38, 76, 157, 0.5);
  }
  .ant-checkbox-inner {
    border-color: #264C9D;
    width: 18px;
    height: 18px;
    border-radius: 0;
    &:after {
      left: 30%;
    }
  }
}

.ant-input-mask.large {
    height: 60px;
    padding: 6px 25px;
    font-size: 30px;
}

@media (max-width: 960px) {
  .ant-input-lg, .ant-input-mask.large {
    font-size: 22px;
    height: 46px;
    padding: 6px 16px;
  }
  .ant-form-item-control {
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .ant-input-lg, .ant-input-mask.large {
    font-size: 16px;
    height: 44px;
    padding: 6px 16px;
  }
  .ant-form-item-control {
    line-height: 40px;
  }
  .ant-modal.modal-reg {
    .ant-form-item {
      margin-bottom: 14px;
    }
    .ant-modal-title {
      font-size: 24px;
    }
  }
  .ant-modal {
    .ant-modal-content .ant-btn-lg {
      width: 100%;
      font-size: 20px;
      height: 50px;
    }
  }
}

.ant-form-item .ant-form-explain {
  position: absolute;
  bottom: -22px;
  font-size: 11px;
}

.ant-select-dropdown-menu {
  max-height: 300px;

  .ant-select-dropdown-menu-item:hover {
    color: #264C9D;
    background: #e7efff;
  }
  .ant-select-dropdown-menu-item-selected {
    color: #fff;
    background: #264C9D!important;
    &:hover {
      background: #264C9D;
      color: #fff;
    }
  }
}

.ant-btn-link {
  &:before {
    left: initial;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #fff;
    &:after {
      border-right: 4px solid #264C9D;
      border-bottom: 4px solid #264C9D;
      width: 10px;
      height: 20px;
      margin-top: -5px;
    }
  }
}

.ant-upload-select {
  width: 100%;
}

.upload-error {
  color: #f5222d;
  margin-top: 5px;
  font-size: 11px;
}

.box-upload {
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: 1px solid #274c9d;
  padding-left: 10px;
  color: #99abd1;
}

.has-error {
  .box-upload {
    border-color: #f5222d;
  }
}

.form-group {
  .box-upload {
    button.ant-btn {
      width: auto;
      min-height: 36px;
      border: 0;
    }
    .select {
      color: #333;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
