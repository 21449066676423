.ant-menu-horizontal {
  border: 0;
  display: flex;
  justify-content: space-between;

  .ant-menu-item-selected {
    border-bottom: 5px solid #00FAFC;
  }

  .ant-menu-item {
    border-width: 5px;
    padding: 0 6px;
    margin-left: 0;

    &:hover {
      border-bottom: 5px solid #00FAFC;
    }
    a, span {
      line-height: 30px;
      display: inline-block;
      transition: color .3s;
    }
  }

  @media (min-width: 960px) {
    &.menu-main, &.menu-advantages, &.menu-conditions-footer, &.menu-directions, &.menu-about {
      li.ant-menu-item * {
          color: #264C9D;

          &:hover {
            color: #264C9D;
          }
      }
    }

    &.menu-conditions, &.menu-requirements {
      li.ant-menu-item * {
          color: #fff;

          &:hover {
            color: #fff;
          }
      }
    }
  }

  @media (max-width: 960px) {
    &  > .ant-menu-submenu {
      display: block!important;
      border: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
    li.ant-menu-item {
      display: inline-flex!important;
      border-width: 5px;
      margin: 20px 10px 0 20px;
      min-height: 45px;
      &:hover {
        border-color: transparent;
      }

      a {
        position: absolute;
        width: 200px;
        left: -20px;
        span {
          margin-left: 20px;
        }
      }

      * {
        color: #fff;
        &:hover {
          color: #fff;
        }
      }

      &.ant-menu-item-selected {
        a {
          span {
            border-bottom: 5px solid #00FAFC;
          }
        }
      }
      &.btn-submit {
        span {
          position: absolute;
          width: 200px;
          left: -20px;
          padding-left: 20px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .ant-menu-horizontal {
    .ant-menu-item {
      padding: 0 2px;
      border-width: 4px;

      a, span {
        line-height: 40px;
      }
    }
  }
  .ant-menu {
     font-size: 11px;
  }
}

.ant-menu-item {
  font-weight: bold;
}

@media (max-width: 960px) {
  .ant-layout-header {
    .bar1, .bar2, .bar3 {
      display: block;
      width: 35px;
      height: 5px;
      background-color: #264C9D;
      margin: 6px 0;
      transition: 0.4s;
    }
    &.active {
      .toggle {
        left: 5px;
        top: 15px;
        &:before {
          content: '';
          display: inline-block;
          width: 30px;
          height: 30px;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MS4wMyA1MS4wMyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT7QoNC10YHRg9GA0YEgMjwvdGl0bGU+PGcgaWQ9ItCh0LvQvtC5XzIiIGRhdGEtbmFtZT0i0KHQu9C+0LkgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSItNS4yNyIgeT0iMjAuMjIiIHdpZHRoPSI2MS41NyIgaGVpZ2h0PSIxMC42IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAuNTcgMjUuNTIpIHJvdGF0ZSgtNDUpIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSItNS4yNyIgeT0iMjAuMjIiIHdpZHRoPSI2MS41NyIgaGVpZ2h0PSIxMC42IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNS41MiAtMTAuNTcpIHJvdGF0ZSg0NSkiLz48L2c+PC9nPjwvc3ZnPg==');
        }
        .bar1, .bar2, .bar3 {
          display: none;
        }
      }
      .menu-advantages,.menu-directions,.menu-conditions-footer {
        & + .toggle:before {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MS4wMyA1MS4wMyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRjOWQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT7QoNC10YHRg9GA0YEgMTwvdGl0bGU+PGcgaWQ9ItCh0LvQvtC5XzIiIGRhdGEtbmFtZT0i0KHQu9C+0LkgMiI+PGcgaWQ9IkxheWVyXzEiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSItNS4yNyIgeT0iMjAuMjIiIHdpZHRoPSI2MS41NyIgaGVpZ2h0PSIxMC42IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAuNTcgMjUuNTIpIHJvdGF0ZSgtNDUpIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSItNS4yNyIgeT0iMjAuMjIiIHdpZHRoPSI2MS41NyIgaGVpZ2h0PSIxMC42IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNS41MiAtMTAuNTcpIHJvdGF0ZSg0NSkiLz48L2c+PC9nPjwvc3ZnPg==');
        }
      }
    }
  }
  .ant-menu-horizontal.menu-main {
    li * {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .ant-menu-item {
      margin-top: 20px;
      border-width: 5px;
      &:hover {
        color: #fff;
      }
    }
  }
  .ant-menu {
    font-size: 16px;
  }
  .menu-advantages,.menu-conditions-footer,.menu-directions {
    & + .toggle {
      .bar1,.bar2,.bar3 {
        background-color: #fff;
      }
    }
    &.ant-menu {
      background-color: #fff;
      &.ant-menu-horizontal li.ant-menu-item  * {
        color: #264C9D;
      }
    }
  }
}
